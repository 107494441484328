import { TermsProps } from '../../@interface/terms'

export const dataTermsGrantedCreditCard: TermsProps = {
  title: 'Termos de Uso do Cartão de Crédito com Garantia',
  subtitle: 'de uso do Cartão de Crédito com Garantia',
  update: '5 de junho de 2024',
  about: {
    title: 'Termo',
    introduction: `
    Este documento (“Termos de Uso do Cartão de Crédito com Garantia”) apresenta as condições de uso do cartão de crédito com garantia (“Cartão de Crédito Cora com Garantia”, “Cartões com Garantia” ou, individualmente, “Cartão com Garantia”) disponibilizados pela Cora Sociedade de Crédito, Financiamento e Investimento S.A., sociedade inscrita sob o nº 37.880.206/0001-63 (“CORA” ou “Nós”) e qualificada conforme Termos e Condições Gerais de Uso da Conta Cora e Termos de Uso do Cartão de Crédito Cora. (“CORA” ou “Nós”), na qual Você mantém sua conta de pagamentos (“Conta Cora”).
    <br/><br/>Ao utilizar-se dos Serviços aqui descritos e oferecidos pela CORA, você celebra um contrato conosco e assume os direitos e obrigações que apresentamos ao longo destes Termos de Uso.
    <br/><br/>Você deve ler atentamente estes Termos de Uso, por inteiro, pois a utilização dos Serviços implica sua total concordância com as disposições deste documento. Caso não concorde com qualquer disposição deste instrumento, não utilize os serviços.
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          about: '',
          intro: '',
          items: [
            {
              item: `O presente Termo e os direitos e obrigações aqui contidos são complementares aos <a href="https://www.cora.com.br/termos-uso/">Termos e Condições Gerais de Uso da Conta Cora</a>, <a href="https://www.cora.com.br/termos-de-uso-cartao-de-credito/">Termos de Uso do Cartão de Crédito Cora</a> e a <a href="https://www.cora.com.br/politica-privacidade/">Política de Privacidade</a>, que se aplicam a todos os titulares de Conta Cora e devem ser interpretados em conjunto.`,
            },
            {
              item: `As definições e condições aqui apresentadas foram retiradas dos <a href="https://www.cora.com.br/termos-de-uso-cartao-de-credito/">Termos de Uso do Cartão de Crédito Cora</a> e servem para o melhor entendimento dos termos utilizados para o Cartão de Crédito Cora com Garantia. Quaisquer termos em letras maiúsculas que não sejam definidos aqui terão suas definições estabelecidas em nossos Termos de Uso.`,
            },
            {
              item: `Nestes Termos de Uso, os termos abaixo iniciados em letras maiúsculas deverão ter o seguinte significado:
                <br/><br/>Conta Cora: é a conta de pagamento digital pré-paga destinada à realização de transações de pagamento.
                <br/><br/>Empresa Selecionada: pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, e selecionada, à critério da CORA, como elegível para obtenção do Cartão de Crédito com Garantia;
                <br/><br/>Sua Empresa: pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, cujos representantes são autorizados a acessar as Aplicações e/ou utilizar os Serviços.
                <br/><br/>Usuário e/ou Usuários: pessoa física representante de Sua Empresa, com poderes atribuídos pelos documentos societários de Sua Empresa, procuração.
                <br/><br/>Você: usuário responsável pelo cadastro de Sua Empresa para acesso aos Serviços.
                <br/><br/>Reserva de Limite: valor existente na Conta Cora de Sua Empresa, reservado por Você, para propiciar a geração de limite de crédito e consequente utilização do Cartão de Crédito com Garantia, condicionado à cessão fiduciária desse crédito para a CORA em garantia ao cumprimento das obrigações de pagamento assumidas pela Sua Empresa em função da utilização do Cartão de Crédito com Garantia.
              `,
            },
          ],
        },
        {
          head: 'Funcionamento do Cartão de Crédito com Garantia',
          about: '',
          intro: '',
          items: [
            {
              item: `Para que o Usuário possa utilizar o Cartão de Crédito com Garantia, este deverá ter cartão de débito ativo em sua Conta Cora e transferir quantias de sua conta de pagamento para a Reserva de Limite, constituindo uma garantia fiduciária em favor da Cora para a realização de transações na modalidade de crédito, observado o limite criado em função do valor aportado na Reserva de Limite.`,
            },
            {
              item: `A Sua Empresa poderá distribuir a utilização do Cartão de Crédito com Garantia em até 2 (dois) cartões físicos e 2 (dois) cartões digitais, solicitados pelo Aplicativo Cora.`,
            },
            {
              item: `Caso o Usuário deseje cancelar o Cartão de Crédito com Garantia, basta adimplir os valores em aberto da fatura e resgatar os valores remanescentes destinados para a Reserva de Limite.`,
            },
          ],
        },
        {
          head: 'Utilização da Reserva de Limite',
          about: '',
          intro: '',
          items: [
            {
              item: `O Usuário poderá transferir valores de R$100,00 (cem reais) até R$5.000,00 (cinco mil reais) para a Reserva de Limite, sendo que, ao transferir valores para a Reserva de Limite, o crédito relativo à quantia definida pelo Usuário (i) será automaticamente cedido fiduciariamente em favor da CORA; (ii) ficará retido a título de garantia ao cumprimento das obrigações de pagamento assumidas pela Sua Empresa em função da utilização do Cartão de Crédito com Garantia até o cumprimento integral dessas obrigações; e (iii) ficará indisponível para movimentação até o limite do montante comprometido com compras já realizadas no Cartão de Crédito com Garantia.`,
            },
            {
              item: `A Sua Empresa poderá realocar os valores alocados na Reserva de Limite a qualquer momento, retirando-os da Reserva de Limite, desde que estes não estejam comprometidos com compras já realizadas no Cartão de Crédito com Garantia.`,
            },
            {
              item: `A quantia definida como Reserva de Limite poderá ser distribuída e utilizada, em sua totalidade, por todos os cartões existentes na Conta Cora.`,
            },
          ],
        },
        {
          head: 'Pagamento da Fatura do Cartão de Crédito com Garantia',
          about: '',
          intro: '',
          items: [
            {
              item: `O pagamento da fatura até a data do vencimento será feito através da sua Conta Cora, mediante débito automático que Você expressamente autoriza, em caráter irrevogável e irretratável, utilizando o saldo na Conta Cora da Sua Empresa. Este valor será composto pelo valor total dos lançamentos em aberto no Cartão de Crédito com Garantia.`,
            },
            {
              item: `Caso não haja saldo suficiente na Conta Cora para o adimplemento do pagamento da fatura, o valor de garantia retido na Reserva de Limite será automaticamente utilizado para a quitação da fatura, diminuindo proporcionalmente ao montante utilizado da Reserva de Limite o limite de crédito disponível da Sua Empresa.`,
            },
            {
              item: `Caso Sua Empresa utilize o seu Cartão de Crédito com Garantia além do valor de garantia retido na Reserva de Limite, a Cora poderá aplicar, no valor que exceder a garantia, os cálculos de cobrança dos <a href="https://www.cora.com.br/termos-de-uso-cartao-de-credito/">Termos de Uso do Cartão de Crédito Cora</a> como juros remuneratórios, encargos, multa e juros moratórios. Sua Empresa poderá, ainda, ter seu nome inscrito nos órgãos de proteção ao crédito.`,
            },
          ],
        },
        {
          head: 'Condições Gerais do Cartão de Crédito com Garantia',
          about: '',
          intro: '',
          items: [
            {
              item: `Você se compromete a não utilizar os serviços do Cartão de Crédito com Garantia para a realização de pagamentos relacionados: (I) a atividades ilegais, tais como, lavagem de dinheiro, estelionato, realização de fraudes contra terceiros, jogos de azar e apostas em geral; (II) à compra e venda de produtos e serviços considerados ilegais pela legislação brasileira; (III) à venda de produtos ou serviços identificados pelos órgãos governamentais como tendo uma alta probabilidade de serem fraudulentos ou que representem risco à segurança física e financeira do consumidor; (IV) a produtos que incentivem, promovam, facilitem ou ensinem pessoas a praticarem atividades ilegais; e (V) à promoção de ódio, violência, racismo ou outras formas de intolerância discriminatória.`,
            },
            {
              item: `Cláusula Mandato. A Empresa Selecionada e os seus Usuários desde já nomeiam a CORA, em caráter irrevogável e irretratável, sua bastante procuradora, com poderes especiais para, em seu nome e por sua conta, (i) aplicar os recursos relativos à Reserva de Limite em produtos de investimento, inclusive, mas não limitados a, Certificado de Depósito Bancário (CDB) que venha(m) a ser emitido(s) por qualquer instituição financeira do mesmo grupo econômico que a CORA,e (ii) ceder fiduciariamente o respectivo investimento e/ou título dele representativo e seus rendimentos à CORA em garantia às obrigações de pagamento assumidas pela Sua Empresa em função da utilização do Cartão de Crédito com Garantia até o cumprimento integral dessas obrigações, outorgando-lhe poderes especiais para assinar todo e qualquer documento que se fizerem necessários para tanto, e movimentar valores, acertar prazos e condições de resgate, bem como juros e rendimentos incidentes e, ainda, substabelecer em todo ou em parte os poderes aqui outorgados.
                <br/><br/>Informações Adicionais
                <br/><br/>Limitação de responsabilidade. Sua Empresa reconhece que todo sistema que depende de rede mundial de computadores está sujeito a falhas e indisponibilidade que, portanto, não acarretarão na responsabilização da CORA e/ou das Parceiras.
                <br/><br/>Cookies. Ao acessar ou navegar pelas plataformas web da CORA você concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade.
                <br/><br/>Cessão. A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa, mediante simples notificação prévia a Sua Empresa, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.
                <br/><br/>Alterações nos Termos de Uso. Estes Termos de Uso podem ser alterados a qualquer tempo e, sempre que ocorrer qualquer modificação, Sua Empresa será informada previamente. Sua Empresa demonstrará que concorda com todas as modificações se continuar a utilizar os Serviços após a alteração dos Termos de Uso. Caso não concorde com os novos Termos de Uso, Sua Empresa poderá rejeitá-los, realizando o cancelamento dos Serviços.
                <br/><br/>Prazo de Vigência. Estes Termos de Uso e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação por Sua Empresa.
                <br/><br/>Lei Aplicável. Estes Termos de Uso e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.
                <br/><br/>Dados. A Empresa Selecionada desde já autoriza a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras, pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário.
              `,
            },
          ],
        },
      ],
    },
  ],
}
