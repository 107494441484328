import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsGrantedCreditCard } from '../data/terms/terms-granted-credit-card'

const TermsGrantedCreditCardPage = () => (
  <TermsPages
    data={dataTermsGrantedCreditCard}
    linkCanonical="termos-de-uso-do-cartao-de-credito-com-garantia"
  />
)
export default TermsGrantedCreditCardPage
